import  { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import InputText from "../../../components/atoms/InputText";
import { Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Label from "../../../components/atoms/Label";
import ArrowLeft from "../../../images/icons/arrow_left.svg";
import Navbar from "../../../components/molecules/Navbar";
import Footer from "../../../components/molecules/Footer";
import FeemonkText from "../../../components/atoms/FeemonkText";
import { DataContext } from "../../../context/DataContext";
import { DataContextType } from "../../../types/DataContextType";
import { createApplication } from "../../../services/application";
import axios from "axios";
import moment from "moment";
import { ApplicantStage } from "../../../utils/applicationStage";
import { encode } from "punycode";
import axiosInstance from "../../../../helpers/axios";
import CustomTextField from "../../../../Components/Mainpage/Components/TextField/TextField";
import CustomAutoComplete from "../../../../Components/Mainpage/Components/AutoComplete/AutoComplete";
import {Button} from "@mui/material";
interface Institute {
  brandName: string
}

function StudentDetails() {
  const navigate = useNavigate()
  const location = useLocation()
  const stateData = location?.state?.data
  const [studentName, setStudentName] = useState(stateData?.selectedCard==='parent'? '':  stateData?.studentName)
  const [instituteName, setInstituteName] = useState(stateData?.instituteName)
  const [courseName, setCourseName] = useState(stateData?.course)
  const [courseFees, setCourseFees] = useState(stateData?.courseFees)
  const [instituteList, setInstituteList] = useState<Institute[]>([])
  const [email, setEmail] = useState('')
  console.log(stateData, 'student details page')

  

  useEffect( () => {
    getEmail()
    if (stateData?.studentName || stateData?.courseFees || stateData?.instituteName || stateData?.course) {
      setStudentName(stateData?.selectedCard==='parent'? '':  stateData?.studentName);
      setCourseName(stateData?.courseName ? stateData.courseName : stateData?.course ? stateData.course : '');
      setInstituteName(stateData.instituteName || '');
      setCourseFees(stateData.courseFees || '');
    } else {
      getStudentDetails();
    }

  }, [location])

  const getStudentDetails = async () =>{
    const applicationId = stateData?.existing? stateData?.applicationId: stateData?.application
    const response = await axios(`${process.env.REACT_APP_DASHBOARD_URL}/summary/user?id=${stateData?.userId}&applicationId=${applicationId}`)
    if(response?.status===200){
      const {studentName, courseFees, instituteName, courseName} = response?.data?.data
      setStudentName(studentName)
      setCourseName(courseName)
      setInstituteName(instituteName)
      setCourseFees(courseFees)
    }
    
  }

  const getEmail = async () => {
    try {
      const applicationId = stateData?.existing? stateData?.applicationId: stateData?.application
      const response: any = await axios(`${process.env.REACT_APP_DASHBOARD_URL}/summary/user?id=${stateData?.userId}&applicationId=${applicationId}`)
      if(response?.status===200){
          const {email} = response?.data?.data
          setEmail(email || stateData?.email)
      }
  } catch (err) {
      console.log(err)
    }
    
  }
  const getInstituteInfo= async (searchVal: any)=>{
    setInstituteName(searchVal)
      try {
        if(searchVal?.length>=4){
            const response = await axiosInstance(`${process.env.REACT_APP_DASHBOARD_URL}/institute/info/name?name=${searchVal}`)
            const list = response?.data?.data
            setInstituteList(list)
          }
      } catch (error) {
        setInstituteList([])
        console.log(error, 'institute api err')
      }
 
  }
   

  const handleInstituteSelect = (name: string)=> {
    setInstituteName(name)
    setInstituteList([])
  }
  const validateEmail = (email: any) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email)
  
  };


  const updateEmail = async () => {
    const headers = {
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcHBsaWNhdGlvbnMtYmFja2VuZCIsInVzZXJfaWQiOjUsImVtYWlsIjoic2FpcmFta0BmZWVtb25rLmNvbSIsImlhdCI6MTcxNjg3Mzc4NjY5Nywicm9sZSI6ImNybSIsImRpc3BsYXlfbmFtZSI6IlNhaSBSYW0gUmVkZHkiLCJtb2JpbGUiOiI2MzA5MTQ4MzMzIiwiZXhwIjoxNzE2ODc0MzkxNDk3fQ.lPHnxTija-VpfqMzFWfJJ5fkHDkWJYM-kt2ybO7AgA8`,
      "Content-Type": "application/json",
    };
    try {
      const applicationId = stateData?.existing ?  stateData?.applicationId : stateData?.application
      const response = await  axios.post(`${process.env.REACT_APP_DASHBOARD_URL}/summary/update`, {email, applicationId, userId:stateData?.userId}, {headers})
    } catch (err) {
       console.log(err)
    }
  }


  const handleContinue = async () => {
    updateEmail()
     const data = {
      applicationId: stateData?.existing ?  stateData?.applicationId : stateData?.application,
      userId: stateData?.userId,
      courseFees:  courseFees,
      instituteName: instituteName,
      courseName: courseName,
      isCoapplicant: true,
      loanAmount: courseFees,
      studentName: studentName,
      channel:"2"
    }
    const authToken = localStorage.getItem("auth_token") || "";
   
    try {
      const response = await fetch(`${process.env.REACT_APP_DASHBOARD_URL}/admin/application/update`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        console.log(response)
      }
  
      const result = await response.json();
      const passData = {...location?.state?.data, fees: courseFees, courseFees}
      navigate("/bank-statement-upload", {
          state: { data:  passData },
      });
      return true
    } catch (error) {
      console.log(error, 'error')
      return false;
    }

 }


  return (
    <div className={styles.body}>
   
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
             <div className={styles.title}>
                <h1 className={styles.headerText}>Student Details</h1>
             </div>
           <div className={styles.studentFieldsContainer}>
          {stateData?.selectedCard==='parent' && <CustomTextField label='Student Name' value={studentName} handleTextFieldOnChange={(sName: any) => setStudentName(sName)} />}
          <CustomTextField label='Email' value={email} handleTextFieldOnChange={(email: any) => setEmail(email)} />
          <CustomAutoComplete label={'Institute Name'} id='instituteName' value={instituteName} options={instituteList} handleAutoCompleteChange={(name: any) => getInstituteInfo(name)} /> 

          <CustomTextField label='Class / Course Name' value={courseName} handleTextFieldOnChange={(course: any) => setCourseName(course)} />
          <CustomTextField label='Course Fee' value={courseFees} type='number' handleTextFieldOnChange={(fees: any) => setCourseFees(fees)} />
       <div style={{alignSelf:'center', marginTop:'5px'}}>
            <Button   color='error' variant='contained' sx={{textTransform:'none', fontFamily:'Outfit'}} onClick={handleContinue}
                  disabled={(stateData?.selectedCard !== 'student' && !studentName) || !instituteName || !courseName || !courseFees || !validateEmail(email)}
                
            >Continue</Button>
          </div>
          </div> 
         
        </div>
        <br />
        <Footer />
      </div>
    </div>
  );
}

export default StudentDetails;
